@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@200&display=swap');
.App {
  font-family: "Poppins";
  box-sizing: border-box;
  width: 100%;
  background-color: black;
}
html {
  background-color: black;
}

.links-section {
  height: 50vh;
  width: 100%;
  margin-top: 150px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.link-section-one {
  /* border: 1px solid red; */
  height: 50vh;
  width: 35%;
  margin-left: 40px;
  background: url("./assets/dfwrgerger.png");
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position-x: center;
  cursor: pointer;

  background-position-y: center;
}
.link-section-two {
  /* border: 1px solid red; */
  height: 50vh;
  width: 35%;

  background: url("./assets/wefwrgetrget.png");
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position-x: center;
  background-position-y: center;
}

.narbar2-container {
  /* border:1px solid red; */
  height: 20vh;
  width:100%;
  /* background: black; */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar2-logo{
  /* border: 1px solid red; */
  height: 20vh;
  width: 15%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.navbar2-hamburger{
  /* border: 1px solid red; */
  height: 20vh;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 15%;
}
.navbar2-image-container {
  height: 14vh;
  /* border: 1px solid red; */
  border-radius: 66px;
  width: 45%;
  background-image: url(./assets/logo.png);
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar2-image-container img {
  width: 100%;
  /* height: 13vh; */
}

.navbar2-hamburger-container {
  height: 14vh;
  /* border: 1px solid red; */
  /* border-radius: 66px; */
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}



/* concept page  */
.concept-page {
  height: 68vh;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10vh;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin-animation {
  animation: spin 5s infinite linear; /* Change duration or animation type as needed */
}
.navplusconcept {
  background: url("./assets/ttytyydf.png");
  padding: 0px;
  margin: 0px;
  background-size: cover;
  background-repeat: no-repeat;

}
.navplusconceptgov {
  background: url("./assets/srtedgfdfgdrgr.png");
  padding: 0px;
  margin: 0px;
  height: 165vh;
  /* border: 1px solid red; */
  background-size: cover;
  background-repeat: no-repeat;
}
.navplusconceptteams {
  background: url("./assets/grfherjtytesfgh.png");
  padding: 0px;
  margin: 0px;
  background-size: contain;
  background-repeat: no-repeat;
}
.navplusconceptcontactus {
  background: url("./assets/grfherjtytesfgh.png");
  padding: 0px;
  margin: 0px;
  background-size: contain;
  background-repeat: no-repeat;
}
.concept-page-middle-text {
  /* border: 1px solid red; */
  height: 23vh;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  flex-direction: column;
  margin-top: 10vh;


}
.concept-page-middle-text h3 {
  font-family: Jost;
  font-size: 56px;
  padding: 0px;
  margin: 0px;
  font-weight: 300;

}
.concept-page-middle-text p {
  font-family: Jost;
  font-size: 19px;
  padding: 0px;
  font-weight: 200;
  margin: 0px;
  width: 58%;
  margin: auto;
  text-align: center;
  

}
::-webkit-scrollbar {
  display: none;
}


/* sercices  */

.services-new-container {
  /* border: 1px solid red; */
  height: 203vh;
  color: white;
  background: black;
  padding-top: 10vh;
}
.service-new-title {
  height: 7vh;
  width: 80%;
  margin-left: 100px;
  /* padding-left: 10%; */
  /* border: 1px solid red; */
  background: url("./assets/dsafwerfwdsdasdas.png");
background-size: contain;

background-repeat: no-repeat;
}

.service-new-content {
  /* border: 1px solid red; */
  height: 95vh;
  width: 100%;
  margin: auto;
  /* margin-top: ; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.service-new-content-ind {
  height: 70vh;
  width: 35%;
  background: url("./assets/rthrtjdfsdgwrgdf.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: opacity 0.3s ease; /* Fades out */
}

.service-new-content-ind::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("./assets/retyukjyhtgrefgrg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  opacity: 0;
  transition: opacity 0.3s ease; /* Fades in */
}

.service-new-content-ind:hover::after {
  opacity: 1; /* New image fades in */
}

.service-new-content-ind2 {
  height: 70vh;
  /* border: 1px solid red; */
  width: 35%;
  background: url("./assets/ergdfgwefrdsfsdf.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position-x: center;
  background-size: cover;
  background-position-y: bottom;
  cursor: pointer;
  transition: transform 0.3s ease;

}
.service-new-content-ind2:hover {
  filter: brightness(1.5);
  background: url("./assets/juythgdfsfghj.png");
  height: 70vh;
  /* border: 1px solid red; */
  width: 35%;

  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position-x: center;
  background-size: cover;
  background-position-y: bottom;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Fades in */
}
.service-new-content-ind3 {
  height: 70vh;
  /* border: 1px solid red; */
  width: 35%;
  background: url("./assets/jrhtergfgsdfsdf.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position-x: center;
  background-size: cover;
  background-position-y: bottom;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Fades in */
}
.service-new-content-ind3:hover {
  height: 70vh;
  /* border: 1px solid red; */
  width: 35%;
  background: url("./assets/yjukyijhgfedfsgh.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position-x: center;
  background-size: cover;
  background-position-y: bottom;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Fades in */
}
.service-new-content-ind4 {
  height: 70vh;
  /* border: 1px solid red; */
  width: 35%;
  background: url("./assets/tyjuiukyjhfgds.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position-x: center;
  background-size: cover;
  background-position-y: bottom;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Fades in */
}
.service-new-content-ind4:hover {
  height: 70vh;
  /* border: 1px solid red; */
  width: 35%;
  background: url("./assets/dfgfhjkjetwsdfgh.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-position-x: center;
  background-size: cover;
  background-position-y: bottom;
  cursor: pointer;
  transition: opacity 0.3s ease; /* Fades in */
}

.web-section {
  height: 29vh;
  width: 100%;
  background: url("./assets/web3image.png");
  background-size: cover;
  background-repeat: no-repeat;
  /* border: 1px solid red; */
  margin-top: 6vh;
  color: white;
  /* margin-bottom: 10vh; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

}
.container-scroll-1 {
  /* border: 1px solid red; */
  /* width: 100%; */
  padding:0.4% 0%;
  display: flex;
}
.text-container-1 {
  text-align: center;
font-family: Jost;
font-size: 42px;
font-style: normal;
font-weight: 500;
line-height: 29px; /* 69.048% */
padding: 1.2% 2.6%;
/* border: 1px solid red; */
}
.text-container-2 {
  text-align: center;
  font-family: Jost;
  font-size: 42px;
  font-style: normal;
  padding: 1.2% 2.6%;
/* border: 1px solid red; */

  font-weight: 500;
  line-height: 29px; /* 69.048% */
}


.svg-container-1 {
padding: 0.6% 1.6%;
display: flex;
align-items: center;
justify-content: center;
/* border: 1px solid red; */

}
.stroke-text {
  -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.6px white;
}


.revert-direction {
  display: flex;
  /* overflow: hidden; */
  width: 100%; /* Adjust as needed */
  /* border: 1px solid red; */
  width: 100%;
  padding:0.4% 0%;
  display: flex;
  width: 100%;
}

.container-scroll-1 {
  display: flex;
  /* overflow: hidden; */
  width: 100%;
  

}

/* Define animation for scrolling left */
@keyframes scrollLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%); /* Adjust as needed for the width of content */
  }
}

/* Define animation for scrolling right */
@keyframes scrollRight {
  from {
    transform: translateX(-100%); /* Adjust as needed for the width of content */
  }
  to {
    transform: translateX(0);
  }
}

/* Apply animations to elements */
.container-scroll-1{
  animation: scrollLeft 10s linear infinite; /* Adjust duration as needed */
}

.revert-direction{
  animation: scrollRight 10s linear infinite; /* Adjust duration as needed */
}



/* video section 4 */

.video-section {
  margin-top: 10vh;
  width: 100%;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.video-section video {
  width: 100%;
  height: 100%;
  object-fit: contain
}


/* .articles-section  */


.articles-section {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 8vh;


  height: 150vh;
  background: url("./assets/r45yrfsdwe.png");
  background-size: contain;
  background-repeat: no-repeat;

}


/* .trusted-by-2  */
.trusted-by-2 {
  width: 100%;
  margin-top: 1vh;


  height: 90vh;
  background: url("./assets/dfwedcd.png");
  background-size: contain;
  background-repeat: no-repeat;

}




/* footer-new */
.footer-new {
  width: 100%;
  margin-top: 8vh;


  height: 120vh;
  background: url("./assets/thrtgefgerg.png");
  background-size: contain;
  background-repeat: no-repeat;

}

.svg-icons {
  /* border: 1px solid red; */
  height: 26vh;
  width: 60%;
  margin: auto;
  margin-bottom: 10vh;
  display: flex;
  align-items: center;
justify-items: center;
flex-direction: column;
}
.svg-icons h2 {
  color: white;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 27px;
}
.svg-icons-wrapper {
  /* border: 1px solid red; */
  height: 12vh;
  width: 58%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.svg-element img {
  width: 60px;
  cursor: pointer;
}
.extra-footer-part {
  /* border: 1px solid red; */
  height: 30vh;
  width: 90%;
  background: url("./assets/4534ref43terter.png");
  margin: auto;
  margin-bottom: 15vh;
  background-size: cover;
}
/* gov-adv-new */
.gov-adv-new {
  height: 150vh;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10vh;
}


/* teams  */

.teams-new {
  height: 686vh;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10vh;
  flex-direction: column;
}
.teams-new-header {
  width: 65%;
  height: 18vh;
  background: url("./assets/dhfgdfgfgert.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 10%;
  /* border: 1px solid red; */
}
.raymond-ratti-1 {
  width:50%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/Raymond.png");
  margin-top: 5vh;
  background-size: contain;
  background-repeat: no-repeat;
}
.raymond-ratti-2 {
  width:100%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/gfdgfjhgfdfgfh.png");
  margin-top: 5vh;
  background-size: contain;
  background-repeat: no-repeat;
overflow: visible;
background-position: center;
background-position-x: 71.9%;
}
.lauraa-2 {
  width:100%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/dfjgdfg.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;
overflow: visible;
background-position: center;
background-position-x: 75.3%;
}
.lauraa-1 {


  width:50%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/lauraa.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;
}


.mattia-2 {





width:100%;
height: 120vh;
/* border: 1px solid red; */
background: url("./assets/fvdgfjhseadfgh.png");
margin-top: 20vh;
background-size: contain;
background-repeat: no-repeat;
overflow: visible;
background-position: center;
background-position-x: 75.3%;


}
.mattia-1 {
  width:50%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/Mattia.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;

}

.pragya-2 {
  width:100%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/fsdjtresdfgrhyj.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: visible;
  background-position: center;
  background-position-x: 75.3%;
  
  }
.pragya-1 {
  width:50%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/Pragya.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;

  


}
.eric-1 {



  width:50%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/Eric.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;

}


.eric-2 {
  width:100%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/dgfhjgtrewrtyu.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: visible;
  background-position: center;
  background-position-x: 75.3%;
}



.gab-2 {
  width:100%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/gwrfwjf.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: visible;
  background-position: center;
  background-position-x: 75.3%;
}

.mina-2 {
  width:100%;
  height: 120vh;
  /* border: 1px solid red; */
  background: url("./assets/minanewfkwed.png");
  margin-top: 20vh;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: visible;
  background-position: center;
  background-position-x: 75.3%;
}
/* contact us  */

.contact-new {
  height: 106vh;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10vh;
  flex-direction: column;
}
.contact-new-header {
  width: 55%;
  height: 30vh;
  background: url("./assets/wdfsfweif.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0% 10%;
  /* border: 1px solid red; */
}

.contact-new-form {
  border: 1px solid red;
margin-top: 10vh;
  width: 75%;
  height: 60vh;
  /* background: url("./assets/dsfjkjrthrfg.png"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  padding-left: 6%;
  border: 2px solid white;
  border-radius: 14px;

}
.contact-new {
  color: #ffffff; /* Text color */
}

.contact-new-header {
  /* Styles for header if any */
}

.contact-new-form {
  /* Styles for the form wrapper */
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 19px;
  border: 2px solid #ffffff; /* Default border color */
  background-color: transparent; /* Transparent background */
  color: #ffffff; /* Text color */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #000000; /* Border color on focus */
}

button {
  padding: 8px 22px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  background-color: #ffffff; /* Button background color */
  color: #000000; /* Button text color */
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

button:hover {
  background-color: #dddddd; /* Button background color on hover */
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-group {
  flex: 1;
  margin-bottom: 20px;
}



.form-group {
  margin-bottom: 20px;
}

input[type="text"],
input[type="email"],
textarea {
  width: calc(100% - 100px); /* Adjusted width for input fields */
  padding: 10px;
  height: 70px;
  border-radius: 6px; /* Adding border radius */
  border: 2px solid #ffffff; /* Default border color */
  background-color: transparent; /* Transparent background */
  color: #ffffff; /* Text color */
  box-sizing: border-box; /* Include padding in width calculation */
}

.footer-new{
  display: flex;
}
.desktop-footer-link-1 {
/* border: 1px solid red; */
height: 10vh;
width: 100px;
left: 37%;
top: 67%;
cursor: pointer;
position: relative;
}
.desktop-footer-link-2 {
  /* border: 1px solid red; */
  height: 10vh;
  width: 100px;
  left: 37%;
  top: 67%;
  position: relative;
cursor: pointer;

  }
  .desktop-footer-link-3 {
    /* border: 1px solid red; */
    height: 10vh;
    width: 100px;
    left: 37%;
cursor: pointer;

    top: 67%;
    position: relative;
    }
    .desktop-footer-link-4 {
      /* border: 1px solid red; */
      height: 10vh;
      width: 100px;
      left: 37%;
cursor: pointer;

      top: 67%;
      position: relative;
      }


.desktop-gov-link {
  width: 60%;
  height: 50vh;
  /* border: 1px solid red; */
  position: relative;
  cursor: pointer;
  top: 70%;
}










@media (max-width: 767px) {
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap");
.App {
  font-family: "Poppins";
}

/* navbar*/

.navbar2-hamburger {
  /* border: 1px solid red; */
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
}
.navbar2-logo {
  /* border: 1px solid red; */
  height: 10vh;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.narbar2-container {
  /* border: 1px solid red; */
  height: 10vh;
  width: 100%;
  /* background: black; */
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navplusconcept {
  background: url(http://localhost:3000/static/media/ttytyydf.4696485….png);
  padding: 0px 20px;
  margin: 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
.mobile-concept-page {
width: 100%;
height: 45vh;
/* border: 1px solid red; */
background: url("./assets/rgethfhfhtr.png");
background-size: contain;
display: flex;
align-items: flex-end;
background-repeat: no-repeat;
}
.services-mobile-container {
  width: 100%;
  height: 194vh;
  /* border: 1px solid red; */
}
.services-mobile-container-firsthalf {
  width: 100%;
  height: 5vh;
  /* border: 1px solid red; */
  background: url("./assets/edgfwrtwefsdf.png");
background-size: contain;
background-repeat: no-repeat;
}

.services-mobile-container-secondhalf {
  width: 84%;
  margin-top: 7vh;
  height:42vh;
  margin: auto;
  /* border: 1px solid red; */
  background: url("./assets/tyjutyjehrgsdfsdfewf.png");
background-size: cover;
background-repeat: no-repeat;
background-position-y: bottom;
}

.services-mobile-container-thirdhalf {
  width: 84%;
  margin-top: 7vh;
  height:42vh;
  margin: auto;
  margin-top: 7vh;
  /* border: 1px solid red; */
  background: url("./assets/rtywtryrwtyreytr.png");
background-size: cover;
background-repeat: no-repeat;
background-position-y: bottom;


}

.services-mobile-container-fourthhalf {
  width: 84%;
  margin-top: 7vh;
  height:42vh;
  margin: auto;
  margin-top: 7vh;
  /* border: 1px solid red; */
  background: url("./assets/htyhjrfgwrdfgdfg.png");
background-size: cover;
background-repeat: no-repeat;
background-position-y: bottom;


}

.services-mobile-container-fifthhalf {
  width: 84%;
  margin-top: 7vh;
  height:42vh;
  margin: auto;
  margin-top: 7vh;
  /* border: 1px solid red; */
  background: url("./assets/fetrytjukyiulkjgfdsa.png");
background-size: cover;
background-repeat: no-repeat;
background-position-y: bottom;


}

.web-section {
  height: 20vh;
  /* border: 1px solid red; */
  background-position: 50%;
}
.container-scroll-1 {
  padding: 2.99% 0%;
}
.revert-direction {
  padding: 2.99% 0%;
}
.video-section {
  margin-top: 1vh;
  width: 100%;
  height: 30vh;
  position: relative;
  overflow: hidden;
}
.articles-section {
  /* border: 1px solid red; */
  width: 100%;
  margin-top: 1vh;


  height: 60vh;
  background: url("./assets/gergerdfsdfser.png");
  background-size: contain;
  background-repeat: no-repeat;

}
.footer-new {
  width: 100%;
  /* display: flex; */
  margin-top: 2vh;
  /* border: 1px solid red; */


  height: 21vh;
  background: url("./assets/fwefsdsd.png");
  background-size: cover;
  background-repeat: no-repeat;

}

.svg-icons {
  /* border: 1px solid red; */
  height: 6vh;
  width: 95%;
  margin: auto;
  margin-bottom: 10vh;
  display: flex;
  align-items: center;
justify-items: center;
flex-direction: column;
}
.svg-icons h2 {
  color: white;
  font-weight: 500;
  letter-spacing: 2px;
  margin-top: 4vh;
  font-size: 18px;
}
.svg-icons-wrapper {
  /* border: 1px solid red; */
  height: 8vh;
  width: 90%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.svg-element img {
  width: 35px;
  cursor: pointer;
}
.extra-footer-part {
  width: 100%;
  /* display: flex; */
  margin-top: 2vh;
  /* border: 1px solid red; */


  height: 23vh;
  background: url('./assets/egetherwer.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 10px;

}
/* links  */
.mobile-footer-link-1 {
  height: 80px;
  width: 50px;
  /* border: 1px solid red; */
  position: relative;
  top: 286px;
  left: 120px;
}
.mobile-footer-link-2 {
  height: 80px;
  width: 50px;
  /* border: 1px solid red; */
  position: relative;
  top: 286px;
  left: 120px;
}
.mobile-footer-link-3 {
  height: 80px;
  width: 50px;
  /* border: 1px solid red; */
  position: relative;
  top: 286px;
  left: 120px;
}
.mobile-footer-link-4 {
  height: 80px;
  width: 50px;
  /* border: 1px solid red; */
  position: relative;
  top: 286px;
  left: 120px;
}
.mobile-footer-link-5 {
  height: 80px;
  width: 30px;
  /* border: 1px solid red; */
  position: relative;
  top: 256px;
  left: 107px;
}
.mobile-footer-link-6 {
  height: 80px;
  width: 100px;
  /* border: 1px solid red; */
  position: relative;
  top: 256px;
  left: 107px;
}
.mobile-footer-link-7 {
  height: 80px;
  width: 100px;
  /* border: 1px solid red; */
  position: relative;
  top: 256px;
  left: 107px;
}
.gov-adv-new-mobile {
  height: 80vh;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;

  align-items: flex-start;
  justify-content: center;
  padding-top: 5vh;
  background: url("./assets/gsgdgswegertg.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.mobile-gov-link-1 {
  width: 100%;
  height: 30vh;
  position: relative;
  top: 500px;
  /* border: 1px solid red; */
}
.mobile-teams {
  /* border: 1px solid red; */
  height: 209vh;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
}
.mobile-teams-block-1 {
  background: url("./assets/gdfswefwerfr.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 20vh;
  /* border: 1px solid red; */

  width: 100%;
}
.mobile-teams-block-2 {
  background: url("./assets/dfsfwefsdf.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 31vh;
  /* border: 1px solid red; */

  width: 100%;
}

.mobile-teams-block-3 {
  background: url("./assets/sfwefwef.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 31vh;
  /* border: 1px solid red; */

  width: 100%;
}

.mobile-teams-block-4 {
  background: url("./assets/fsfwerfrg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 31vh;
  /* border: 1px solid red; */

  width: 100%;
}

.mobile-teams-block-5 {
  background: url("./assets/fwsrgegf.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 31vh;
  /* border: 1px solid red; */

  width: 100%;
}

.mobile-teams-block-6 {
  background: url("./assets/gsferfwefg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 31vh;
  /* border: 1px solid red; */

  width: 100%;
}
.mobile-teams-block-7 {
  background: url("./assets/minaif4nifwe4f.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 31vh;
  /* border: 1px solid red; */

  width: 100%;
}

.mobile-teams-block-8 {
  background: url("./assets/erwrv.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 31vh;
  /* border: 1px solid red; */

  width: 100%;
}

.mobile-raymond-link {
  /* border: 1px solid red; */
  width: 100%;
  height: 31vh;
  position: relative;
  /* top: 10px; */
}
.mobile-laura-link {
  /* border: 1px solid red; */
  width: 100%;
  height: 31vh;
  position: relative;
}
.mobile-mattia-link {
  /* border: 1px solid red; */
  width: 100%;
  height: 31vh;
  position: relative;
}
.mobile-pragya-link {
  /* border: 1px solid red; */
  width: 100%;
  height: 31vh;
  position: relative;
}
.mobile-eric-link {
  /* border: 1px solid red; */
  width: 100%;
  height: 31vh;
  position: relative;
}

.mobile-mina-link {
  width: 100%;
  height: 31vh;
  position: relative;
}

.mobile-gabe-link {
  width: 100%;
  height: 31vh;
  position: relative;
}

.navbar-dropdown {
  border: 1px solid rgb(223, 223, 223);
  list-style: none;
  /* height: 10vh; */
  /* position: relative;
  top: 10; */
  /* position: relative; */    position: relative;
    top: 60px;
  background-color: rgba(0, 0, 0, 0.942);
  font-size: 11px;
}
.navbar-dropdown ul{
  /* border: 1px solid red; */
  list-style: none;
  /* margin-top: 15px; */
  font-size: 11px;
  padding: 0px 17px;

}
.navbar-dropdown ul li{
  margin-top: 0px;
  font-size: 10px;
  /* padding-top: 15px; */

}

.mobile-article-1 {
  /* border: 1px solid red; */
  width: 97%;
  height: 13vh;
  position: relative;
  top: 15%;
}

.mobile-article-2 {
  /* border: 1px solid red; */
  width: 97%;
  height: 13vh;
  position: relative;
  top: 15%;
}
.mobile-article-3 {
  /* border: 1px solid red; */
  width: 97%;
  height: 13vh;
  position: relative;
  top: 15%;
}

.mobile-article-4 {
  /* border: 1px solid red; */
  width: 97%;
  height: 13vh;
  position: relative;
  top: 15%;
}



























































/* contact us  */

.contact-new {
  height: 50vh;
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 1vh;
  flex-direction: column;
}
.contact-new-header {
  width: 100%;
  height: 8vh;
  background: url("./assets/fwefwee3.png");
  background-size: contain;
  background-repeat: no-repeat;
  padding: 0% 0%;
  /* border: 1px solid red; */
}
.navbar2-image-container {
  width: 75%;
}
.contact-new-form {
  border: 1px solid red;
margin-top: 2vh;
  width: 80%;
  height: min-content;
  /* height: 50vh; */
  /* background: url("./assets/dsfjkjrthrfg.png"); */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  font-size: 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* padding-left: 1%; */
  border: 0.8px solid white;
  border-radius:6px;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  /* padding-left: 10px; */

}
.contact-new {
  color: #ffffff; /* Text color */
}

.contact-new-header {
  /* Styles for header if any */
}

.contact-new-form {
  /* Styles for the form wrapper */
}

.form-group {
  margin-bottom: 10px;
  /* border: 1px solid red; */
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  height: 2px;
  border-radius:13px;
  border: 0.8px solid #ffffff; /* Default border color */
  background-color: transparent; /* Transparent background */
  color: #ffffff; /* Text color */
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #000000; /* Border color on focus */
}

button {
  padding: 8px 22px;
  border: none;
  border-radius: 2px;
  font-size: 10px;
  background-color: #ffffff; /* Button background color */
  color: #000000; /* Button text color */
  cursor: pointer;
  background-color: transparent;
  color: white;
  border: 1px solid white;
}

button:hover {
  background-color: #dddddd; /* Button background color on hover */
}

.form-row {
  display: flex;
  gap: 1px;
  align-items: flex-start;
  justify-content: flex-start;
}

.form-group {
  flex: 1;
  margin-bottom: 20px;
  font-size: 10px;
}



.form-group {
  margin-bottom: 20px;
  font-size: 10px;
}

input[type="text"],
input[type="email"],
textarea {
  width: calc(100% - 20px); /* Adjusted width for input fields */
  padding: 10px;
  white-space: nowrap;
  font-size: 10px;
  border-radius: 6px; /* Adding border radius */
  border: 0.8px solid #ffffff; /* Default border color */
  background-color: transparent; /* Transparent background */
  color: #ffffff; /* Text color */
  box-sizing: border-box; /* Include padding in width calculation */
}



























.trusted-by-2-mobile {
  width: 100%;
  margin-top: 1vh;
/* border: 1px solid red; */

  height: 20vh;
  background: url("./assets/dfwedcd.png");
  background-size: contain;
  background-repeat: no-repeat;
}




.links-section-mobile {
  height: 10vh;
  width: 100%;
  margin-top: 5px;
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.link-section-one-mobile {
  height: 20vh;
  width: 35%;
  background: url("./assets/dfwrgerger.png");
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-position-x: center;
  cursor: pointer;

  background-position-y: center;
}
.link-section-two-mobile {
  /* border: 1px solid red; */
  height: 20vh;
  width: 35%;

  background: url("./assets/wefwrgetrget.png");
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position-x: center;
  background-position-y: center;
}

















}



@media (min-width: 900px) {

  .navbar-dropdown {
    border: 1px solid rgb(223, 223, 223);
    list-style: none;
    /* height: 10vh; */
    /* position: relative;
    top: 10; */
    /* position: relative; */    position: relative;
      top: 60px;
      left: -100px;
    background-color: rgba(0, 0, 0, 0.942);
    font-size: 11px;
    /* left: -50px; */
  }
  .navbar-dropdown ul{
    /* border: 1px solid red; */
    list-style: none;
    /* margin-top: 15px; */
    font-size: 11px;
    padding: 0px 20px;
  
  }
  .navbar-dropdown ul li{
    margin-top: 0px;
    cursor: pointer;
    font-size: 19px;
    /* padding-top: 15px; */
  
  }

.articles-section {
  /* border: 1px solid red; */
}

.desktop-article-1 {
  /* border: 1px solid red; */
  width: 97%;
  height: 31vh;
  position: relative;
  top: 15%;
  cursor: pointer;
}

.desktop-article-2 {
  /* border: 1px solid red; */
  width: 97%;
  height: 31vh;
  position: relative;
  top: 15%;
  cursor: pointer;
}
.desktop-article-3 {
  /* border: 1px solid red; */
  width: 97%;
  height: 31vh;
  position: relative;
  top: 15%;
  cursor: pointer;
}

.desktop-article-4 {
  /* border: 1px solid red; */
  width: 97%;
  height: 31vh;
  position: relative;
  cursor: pointer;
  top: 15%;
}









}